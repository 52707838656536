import _ from 'lodash'
import React from 'react'
import { Col, Row } from 'reactstrap'

const HowTwo = () => {
  return (
    <div className='premier-unlock mb-3'>
      <Row className='d-flex justify-content-center'>
        <Col
          md='3'
          className='border border-info text-dark rounded-2 py-2 px-2 text-center help-div'
        > 
        <div className="help-box">
           <a
            href='https://www.easy2show.com/assets/pdf/Exhibitor_Entry_How_2.pdf'
            target='_blank'
            title='General How 2'
          >
            <h4 className='mb-0'>
              <strong>HELP </strong>
              <span> General How 2</span>
            </h4>
          </a>
          </div>
        </Col>
        <Col
          md='3'
          className='border border-info text-dark rounded-2 py-2 px-2 text-center help-div'
        > 
        <div className="help-box">
           <a
             href='https://www.easy2show.com/assets/pdf/How_2_Easy_Entry.pdf'
            target='_blank'
            title='How 2 Easy Entry'
          >
            <h4 className='mb-0'>
              <strong>HELP </strong>
              <span> How 2 Easy Entry</span>
            </h4>
          </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HowTwo
